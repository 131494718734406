import React, { Component } from 'react';
import smoothscroll from 'smoothscroll-polyfill'
import "./Home.css";
import logo from "../../assets/top_logo.png";
import topBanner from "../../assets/top_banner@2x.png";
import introduce from "../../assets/bg_company@2x.png";
import introduceExample from "../../assets/pic_company@2x.png";
import partner from "../../assets/bg_cooperative@2x.png";
import civilization from "../../assets/bg_company_introduction@2x.png";
import relative from "../../assets/bg_contact_us@2x.png";
import relativePhone from "../../assets/pic_contact_phone@2x.png";
import relativeAddress from "../../assets/pic_contact_map@2x.png";
class Home extends Component {

    // constructor(props) {
    //     super(props);
    // }
    componentDidMount(){
        smoothscroll.polyfill()
    }
    state = {
        instroduce1: "杭州翼虎计算机科技有限公司坐落于钱塘江南岸高新技术（滨江）区，由上市公司原始股东投资创立，是一家专业从事软件开发、技术服务、项目实施的科技型企业。公司拥有一批长期专业从事软件开发、软件实施的高学历专业人才，具有雄厚的技术开发实力，全方位满足政府与企业的信息化需求。公司专注于计算机软件系统的研发和应用，拥有成熟的并具备多年的研究及开发经验，以市场需求和技术创新为导向，目前公司致力于远程医疗、远程会议和人工智能方向的软件技术开发，主要内容有：远程会诊、远程预约、远程诊断、远程手术指导与示教、影像管理、远程教育、远程视频会议、AI医疗医疗辅助、AI会议终端等。",
        instroduce2: "公司主要的合作伙伴：青岛海泰新光科技股份有限公司、国药新光医疗科技有限公司等。",
        partnerName1: "青岛海泰新光科技股份有限公司",
        partner1: "系医用成像器械行业的高新技术企业。自2003年成立以来，公司始终坚持高端医用成像器械的自主研发与创新，致力于成为医用成像器械领域世界领先的科技公司，为临床医学提供全套解决方案。海泰新光以医疗应用为重点，在保持并巩固内窥镜领域竞争优势的同时，持续开发与完善微创医疗器械领域的产品线布局。医用内窥镜器械产品包括高清荧光/白光腹腔镜、高清荧光/白光内窥镜光源模组、高清荧光/白光摄像适配器/适配镜头等。光学产品主要包括医用光学产品、工业及激光光学产品和生物识别产品，医用光学产品有牙科内视镜模组、荧光滤光片、美容机滤光片等，工业及激光光学产品有准直/聚焦镜、扫描镜、PBS、NPBS等，生物识别产品有掌纹仪和指纹仪等。",
        partnerName2: "国药新光医疗科技有限公司",
        partner2: "成立于2022年，作为国药集团发展医疗器械工业制造中长期战略的重要布局，公司由中国医疗器械有限公司（国药集团下属企业）和青岛海泰新光科技股份有限公司（688677.SH）联合投资成立，主要从事内窥镜等高端医疗器械的研发、生产、销售及售后等相关技术服务业务。",
    }
    toScroll = (value) => {
        console.log(value);
        // let scrollElement = document.querySelector(".Home-wrap");    // 对应id的滚动容器
        let anchorElement;
        if (value === 1) {
            anchorElement = document.querySelector(".Home-home");

        } else if (value === 2) {
            anchorElement = document.querySelector(".Home-introduce");
        } else if (value === 3) {
            anchorElement = document.querySelector(".Home-partner");
        } else if (value === 4) {
            anchorElement = document.querySelector(".Home-civilization");
        } else if (value === 5) {
            anchorElement = document.querySelector(".Home-relative");
        }
        // console.log(scrollElement, anchorElement)
        // let appEl = document.querySelector(".app"); 
        window.scrollTo({ top: anchorElement.offsetTop, behavior: "smooth" });
        // if (scrollElement) {
        //     // scrollElement.scrollTo(0,{ top: anchorElement.offsetTop, behavior: "smooth" });
        //     scrollElement.scrollTo("100px","100px");
        // }
    }
    toElis1() {
        window.open("https://www.elis-medical.com/")
    }
    toElis2() {
        window.open("https://www.gyxgylkj.com")
    }
    toHaitai(){
        window.open("https://www.novelbeam.com")
    }
    toGuoyao(){
        window.open("http://guoyaoxinguang.com")
    }
    render() {
        return (
            <div className="Home-wrap">
                <div className="Home-nav">
                    <div className="Home-nav-logo">
                        <img src={logo} alt="" />
                    </div>
                    <div className="Home-nav-list">
                        <div className="Home-nav-list-1" onClick={this.toScroll.bind(this, 1)}>主页</div>
                        <div onClick={this.toScroll.bind(this, 2)}>公司介绍</div>
                        <div onClick={this.toScroll.bind(this, 3)}>合作伙伴</div>
                        <div onClick={this.toScroll.bind(this, 4)}>企业文化</div>
                        <div onClick={this.toScroll.bind(this, 5)}>联系我们</div>
                    </div>
                </div>
                <div className="Home-home">
                    <img src={topBanner} alt="" />
                    <div className="Home-home-button">
                        <button onClick={this.toScroll.bind(this, 5)}>马上咨询</button>
                    </div>
                </div>
                <div className="Home-introduce">
                    <img src={introduce} alt="" />
                    <div className="Home-introduce-text">
                        <div className="Home-introduce-text1">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.instroduce1}</div>
                        <div className="Home-introduce-text2">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.state.instroduce2}
                        </div>
                    </div>
                    <div className="Home-introduce-example">
                        <div className="Home-introduce-example-left">
                            <img src={introduceExample} alt="" />
                        </div>
                        <div className="Home-introduce-example-right">
                            <div className="Home-introduce-example-text">
                                <div className="Home-introduce-example-text-li">
                                    <div>用心创作历程</div>
                                    <div>贴合真实医疗操作习惯，极大便利上下级医生和患者的交流</div>
                                </div>
                                <div className="Home-introduce-example-text-li">
                                    <div>PC端</div>
                                    <div>全新的用户体验，便捷不同医院医生之间的交流学习</div>
                                </div>
                                <div className="Home-introduce-example-text-li">
                                    <div>移动端</div>
                                    <div>线上会议，手术指导，远程教学等操作，再也不受场地的限制</div>
                                </div>
                            </div>
                            <div className="Home-introduce-example-to">
                                <button onClick={this.toElis1.bind(this)}>体验案例1</button>
                                <button onClick={this.toElis2.bind(this)}>体验案例2</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Home-partner">
                    <img src={partner} alt="" />
                    <div className="Home-partner-text">
                        <div className="Home-partner-text-1">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span onClick={this.toHaitai.bind(this)}>{this.state.partnerName1}</span>
                            <span>{this.state.partner1}</span>
                        </div>
                        <div className="Home-partner-text-2">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span onClick={this.toGuoyao.bind(this)}>{this.state.partnerName2}</span>
                            <span>{this.state.partner2}</span>
                        </div>
                    </div>
                </div>
                <div className="Home-civilization">
                    <img src={civilization} alt="" />
                </div>
                <div className="Home-relative">
                    <img src={relative} alt="" />
                    <div className="Home-relative-address">
                        <div className="Home-relative-address-1">
                            <img src={relativeAddress} alt="" />
                        </div>
                        <div className="Home-relative-address-2">
                            杭州翼虎计算机科技有限公司
                        </div>
                        <div className="Home-relative-address-3">
                            我们期待与您——携手共进、合作共赢
                        </div>
                        <div className="Home-relative-address-4">
                            电话：0571-87973101
                        </div>
                        <div className="Home-relative-address-5">
                            地址：杭州市滨江区江南大道380号1幢十一层1101室
                        </div>
                    </div>
                </div>
                <div className="Home-bottom">
                    <div>浙ICP备2022007941号-1</div>
                    <div>
                        <img src={relativePhone} alt="" />
                    </div>
                </div>
            </div>);
    }
}

export default Home;
