// import logo from './logo.svg';
import './App.css';
// import LoginPage from "./views/Login/Login";
import Home from "./views/Home/Home"
function App() {
  return (
    <div className="App">
      {/* <p>
        这是一个APP页面~
      </p>
      <LoginPage value={"这是父组件中的值"}></LoginPage> */}
      <Home></Home>
    </div>
  );
}
export default App;
